/* You can add global styles to this file, and also import other style files */

//$styleVersion: 3.0;
// @import '~@spartacus/styles/index';
@use 'sass:math';
@use './styles/variables' as *;
@use './styles/ngb-accordion.scss' as *;
@use './styles/FAQ/faq-search.scss' as *;
@use './styles/FAQ/new-to-alcon.scss' as *;
@use './styles/FAQ/contact-us.scss' as *;
@use './styles/stock-order/toric.scss' as *;
@import 'bootstrap-icons/font/bootstrap-icons';
@import './styles/mixin-widths';
@import './styles/spartacus/asm.scss';
@import './styles/tablet/order-history-mixin.scss';

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('./assets/Fonts/Open_Sans/OpenSans-Regular.ttf');
}

@font-face {
  src: url('./assets/Fonts/Open_Sans/OpenSans-Light.ttf');
  font-family: 'OpenSans-Light';
}

@font-face {
  src: url('./assets/Fonts/Open_Sans/OpenSans-Bold.ttf');
  font-family: 'OpenSans-Bold';
}

@font-face {
  src: url('./assets/Fonts/Open_Sans/OpenSans-SemiBold.ttf');
  font-family: 'OpenSans-SemiBold';
}

//bootsatrp variables
$grid-columns: 10 !default;
$font-family-sans-serif: 'OpenSans-Regular' !default;
$headings-font-family: 'OpenSans-Light' !default;
$h1-font-size: 2.25rem !default;
$h2-font-size: 1.75rem !default;
$body-color: $dimBlack !default;
$badge-border-radius: 12.5px !default;
$badge-padding-y: 5px !default;
$badge-padding-x: 12px !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: $breakpoint-mobile,
  lg: $breakpoint-tablet,
  xl: 1200px,
  xxl: 1900px,
) !default;
@import '/node_modules/bootstrap/scss/bootstrap.scss';

// Tablet view
@import './styles/tablet/cart.scss';
@import './styles/tablet/navigation.scss';

.popover {
  background-color: #ffffff;
  border-radius: rem(4px);
  box-shadow: 0 rem(5px) rem(10px) 0 rgba(35, 35, 35, 0.2);
  color: #232323;
  font-family: $openSans-regular;
  font-size: rem(12px);
  line-height: rem(15px);
  text-align: left;
  border: 0;

  &--sm {
    width: rem(166px);
  }

  &--md {
    width: rem(266px);
  }

  &--lg {
    width: rem(366px);
  }
}

cx-paragraph {
  p {
    b {
      font-weight: bold;
      font-family: $openSans-semiBold;
    }
  }
}

cx-skip-link {
  display: none;
}

@if $grid-columns>0 {
  @media print {
    @for $i from 1 through $grid-columns {
      .col-print-#{$i} {
        @include make-col($i, $grid-columns);
      }
    }
  }
}

/*  Header Navingation overides */

body {
  margin-top: 6rem;

  @media (min-width: 576px) {
    margin-top: 6.25rem;
  }

  @media (min-width: 992px) {
    margin-top: 6.25rem;
  }
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;

  .row {
    margin-left: -20px;
    margin-right: -20px;
  }

  @media (min-width: 1180px) {
    width: 1180px;
  }

  &--no-max-width {
    @media (min-width: 1180px) {
      width: auto;
    }
  }
}

footer {
  .nav-link {
    color: $dimBlack !important;
    font-size: 1rem;
  }
}

header {
  .nav-link {
    color: $white !important;
    font-size: 1rem;
  }
}

/*bootstrap overides*/

h1 {
  @media (max-width: 575px) {
    font-size: 1.875rem;
  }
}

button {
  &:focus {
    outline: none;
  }
}

.btn-primary {
  background-color: #003593;
  border-radius: 20px;
  border: 0px;

  &:disabled {
    background-color: #d4d4d4;
    border: 0px;
    opacity: 1;
  }

  background-color: #003593;
  border-radius: 20px;
}

.btn-secondary {
  background-color: #ffffff;
  border-radius: 20px;
  color: #003593;
}

.bg {
  &-blue {
    background: #e5f5ff;
  }

  &-cream {
    background: $lightCream;
  }
}

.header {
  &-color {
    &_blue {
      color: #003593;
    }
  }
}

.badge {
  &-private-label {
    background-color: rgba(78, 15, 120, 0.2);
    color: #4e0f78;
    font-size: 12px;
    text-align: center;
  }

  &-packsize {
    background-color: rgba(102, 206, 143, 0.2);
    color: #00ae44;
    font-size: 12px;
    text-align: center;
    width: 70px;

    &:hover {
      background-color: #66ce8f;
      color: #ffffff;
    }
  }
}

.header-counter {
  background-color: #00aeef;
  border-radius: 100%;
  color: #ffffff;
  display: inline-block;
  font-family: $openSans-regular;
  font-size: 8px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
}

.cx-icon {
  &.addOrder {
    margin: 0px 12px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &.dtp,
  &.dto {
    svg {
      width: 111px;
      height: 38px;
    }
  }

  &.notification,
  &.closeNotification {
    svg {
      width: 96px;
      height: 96px;
    }
  }

  &.arrowRight {
    svg {
      width: 12px;
      height: 12px;
    }
  }

  &.dropdownExpand {
    svg {
      width: 14px;
      height: 10px;
    }
  }

  &.dropdownExpandSmall {
    svg {
      width: 11px;
      height: 8px;
    }
  }

  &.dropdownCollapse {
    svg {
      width: 14px;
      height: 10px;
    }
  }

  &.dropdownCollapseSmall {
    svg {
      width: 11px;
      height: 8px;
    }
  }

  &.dropdownExpandBlack {
    svg {
      width: 14px;
      height: 10px;
    }
  }

  &.dropdownCollapseBlack {
    svg {
      width: 14px;
      height: 10px;
    }
  }

  &.plpshowmore,
  &.plpshowless {
    svg {
      width: 22px;
      height: 35px;
    }
  }

  &.downloadIcon {
    svg {
      width: 23px;
      height: 21px;
    }
  }

  &.nav-arrow-icon {
    svg {
      width: 11px;
      height: 10px;
    }
  }

  &.close-profile-icon {
    svg {
      height: 24px;
      padding-top: 2px;
      width: 17px;
    }
  }

  &.minicart {
    svg {
      height: 24px;
      width: 17px;
    }
  }

  &.closeMark {
    svg {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }

  &.sortbyHigh {
    svg {
      width: 7px;
      height: 10px;
    }
  }

  &.sortbyLow {
    svg {
      width: 7px;
      height: 10px;
    }
  }

  &.expandDown {
    svg {
      height: 35px;
      width: 22px;
    }
  }

  &.expandUp {
    svg {
      height: 35px;
      width: 22px;
    }
  }

  &.close {
    svg {
      height: 16px;
      width: 15px;
    }
  }

  &.load-icon {
    svg {
      height: 24px;
      width: 23px;
    }
  }

  &.tick-icon {
    svg {
      height: 18px;
      width: 18px;
    }
  }

  &.expandRight {
    svg {
      height: 13px;
      width: 8px;
    }
  }
  &.cartEdit {
    svg {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  &.remove {
    svg {
      height: 14px;
      width: 15px;
      cursor: pointer;
    }
  }

  &.edit {
    svg {
      height: 13px;
      width: 14px;
    }
  }

  &.safeOrdering {
    svg {
      height: 28px;
      width: 18px;
    }
  }

  &.icon-favourite {
    svg {
      height: 24px;
      width: 24px;
    }

    &.heartFilledSm,
    &.heartEmptySm {
      svg {
        height: 15px;
        width: 18px;
      }
    }
  }

  &.hideeye {
    svg {
      width: 25px;
      height: 20px;
    }
  }

  &.showeye {
    svg {
      width: 23px;
      height: 17px;
    }
  }

  &.addOrderOption {
    svg {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }

  &.option {
    svg {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }

  &.copyIcon {
    svg {
      height: 11px;
      width: 11px;
      margin-right: 5px;
    }
  }

  &.editBlue {
    svg {
      height: 15px;
      width: 16px;
      cursor: pointer;
    }
  }

  &.alphabetAsc {
    svg {
      height: 15px;
      width: 15px;
    }
  }

  &.alphabetDesc {
    svg {
      height: 13px;
      width: 15px;
    }
  }

  &.orderHistory {
    svg {
      height: 10px;
      width: 13px;
      margin-right: 5px;
    }
  }

  &.editInfo {
    svg {
      height: 14px;
      width: 12px;
      margin-right: 5px;
    }
  }

  &.iconClock {
    svg {
      width: 90px;
      height: 86px;
      margin: 30px 200px 15px;
    }
  }

  &.individualOrderActionLink {
    svg {
      height: 37px;
      width: 30px;
    }
  }

  &.stockOrderActionLink {
    svg {
      height: 35px;
      width: 35px;
    }
  }

  &.bulkOrderActionLink {
    svg {
      height: 35px;
      width: 32px;
    }
  }

  &.patientOrderActionLink {
    svg {
      height: 37px;
      width: 30px;
    }
  }

  &.backButton {
    svg {
      margin-bottom: 5px;
      margin-right: 12px;
      height: 32px;
      width: 32px;
    }
  }

  &.searchIcon {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  &.questionMark {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  &.contactusthankyou {
    svg {
      height: rem(258px);
      width: rem(210px);
    }
  }

  &.minusEndIcon {
    svg {
      height: 21px;
      width: 21px;
    }
  }

  &.minusIcon {
    svg {
      height: 21px;
      width: 21px;
    }
  }

  &.plusIcon {
    svg {
      height: 21px;
      width: 21px;
    }
  }

  &.partialBackorder {
    svg {
      height: 30px;
      width: 38px;
    }
  }

  &.shipped {
    svg {
      height: 31px;
      width: 38px;
    }
  }
  &.delivered {
    svg {
      height: 30px;
      width: 38px;
    }
  }

  &.newOrder {
    svg {
      height: 34px;
      width: 33px;
    }
  }

  &.cancelled {
    svg {
      height: 31px;
      width: 34px;
    }
  }

  &.backorder {
    svg {
      height: 28px;
      width: 38px;
    }
  }

  &.disabledCounter {
    svg {
      height: 30px;
      width: 100px;
    }
  }

  &.calender {
    svg {
      width: 22px;
      height: 20px;
    }
  }

  &.changePassword {
    svg {
      height: 17px;
      width: 11px;
    }
  }

  &.pageLoader {
    svg {
      height: 76px;
      width: 71px;
    }
  }

  &.successTick {
    svg {
      height: 12px;
      width: 12px;
    }
  }

  &.successClose {
    svg {
      height: 10px;
      width: 10px;
    }
  }

  &.print {
    svg {
      height: 19px;
      width: 21px;
    }
  }

  &.validInput {
    svg {
      height: 25px;
      width: 24px;
    }
  }

  &.disabledMinusCounter {
    svg {
      height: 21px;
      width: 21px;
    }
  }

  &.disabledPlusCounter {
    svg {
      height: 21px;
      width: 21px;
    }
  }

  &.arrowWhite {
    svg {
      width: 12px;
      height: 12px;
    }
  }

  &.disabledEdit {
    svg {
      width: 16px;
      height: 14px;
      cursor: pointer;
    }
  }

  &.paymentIcon {
    svg {
      width: 35px;
      height: 34px;
      margin-left: 10px;
    }
  }

  &.deliveryIcon {
    svg {
      width: 37px;
      height: 31px;
    }
  }

  &.disablePassword {
    svg {
      height: 17px;
      width: 11px;
    }
  }

  &.disabledDelete {
    svg {
      height: 18px;
      width: 16px;
    }
  }

  &.disabledClose {
    svg {
      height: rem(10px);
      margin-right: rem(5px);
      width: rem(10px);
    }
  }

  &.searchOrder {
    svg {
      height: rem(18px);
      width: rem(23px);
    }
  }

  &.refresh {
    svg {
      height: 15px;
      width: 15px;
    }
  }

  &.returnProduct {
    svg {
      height: 27px;
      width: 27px;
    }
  }

  &.invalidInput {
    svg {
      width: 4px;
      height: 22px;
      margin-left: 5px;
    }
  }

  &.blueCloseIcon {
    svg {
      height: 15px;
      width: 15px;
    }
  }

  &.pendingConsolidationBigIcon {
    svg {
      height: rem(40px);
      width: rem(30px);
      cursor: pointer;
    }
  }

  &.pendingConsolidationSmallIcon {
    svg {
      height: rem(20px);
      width: rem(20px);
      cursor: pointer;
    }
  }

  &.accountDetailsConsolidation {
    svg {
      height: rem(20px);
      width: rem(20px);
    }
  }

  &.reOrder,
  &.reOrderDisable {
    svg {
      height: rem(20px);
      width: rem(20px);
      cursor: pointer;
    }
  }

  &.userProfile {
    svg {
      height: rem(23px);
      width: rem(17px);
    }
  }
  &.change {
    svg {
      height: rem(20px);
      width: rem(20px);
    }
  }
  &.visa {
    svg {
      height: rem(17px);
      width: rem(53px);
    }
  }
  &.mastercard {
    svg {
      height: rem(20px);
      width: rem(110px);
    }
  }
  &.orderDetails {
    svg {
      height: 13px;
      width: 12px;
      cursor: pointer;
    }
  }
  &.orderLineIteamInfo {
    svg {
      height: rem(20px);
      width: rem(20px);
    }
  }
  &.counterError {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  &.warningIcon {
    svg {
      width: 14px;
      height: 14px;
      margin: 1px 5px 0 0;
    }
  }
  &.AccessPending {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  &.PermissionExpand {
    svg {
      height: 16px;
      width: 16px;
    }
  }
  &.PermissionCollapse {
    svg {
      height: 11px;
      width: 16px;
      margin-bottom: rem(2px);
    }
  }
  &.informationCircleIcon {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  &.disabledPermissionCollapse {
    svg {
      height: 11px;
      width: 16px;
      margin-top: rem(2px);
    }
  }
  &.permissionCloseDisabled {
    svg {
      height: 16px;
      width: 16px;
    }
  }
  &.permissionCloseEnabled {
    svg {
      height: 16px;
      width: 16px;
    }
  }
}

/*dashboar-widget*/

.dashboard-widget {
  &-header {
    color: #003593;
    font-family: $openSans-light;
    font-weight: normal;
    margin-bottom: 1.75rem;

    @media (max-width: 575px) {
      font-size: 2.25rem;
    }

    @media (min-width: 576px) {
      margin-bottom: rem(32px);
    }
  }

  &-table {
    display: flex;
    flex-direction: column;

    @media (max-width: 575px) {
      padding-top: 0.375rem;
    }

    &-header {
      display: none;
      flex-direction: row;
      margin-bottom: 5px;

      @media (min-width: 576px) {
        display: flex;
      }

      &-col {
        color: #adadad;
        font-family: 'OpenSans-Regular';
        font-size: 12px;
        line-height: 14px;
        text-align: left;
      }
    }

    &-row {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;
      border-bottom: 1px solid #e5f5ff;

      @media (min-width: 576px) {
        flex-direction: row;
        border: 0px;
      }

      &_border {
        border: 1px solid #b6d3e5;
        border-radius: 7px;
        height: 70px;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &-col {
      font-family: 'OpenSans-Regular';
      font-size: 16px;
      line-height: 19px;
      text-align: left;
      width: 100%;

      @media (max-width: 575px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }

      &_semibold {
        font-family: 'OpenSans-SemiBold';
      }

      &_header {
        color: #adadad;
        line-height: 19px;

        @media (min-width: 576px) {
          display: none;
        }
      }
    }
  }

  &-button-hold {
    margin-top: rem(40px);
    font-family: 'OpenSans-SemiBold';
  }
}

/*should be removed*/

.dashboardSection2 {
  img {
    width: 100%;
  }
}

.image-holder {
  img {
    width: 100%;
  }
}

.stock-image-holder {
  img {
    width: 100%;
    height: 64px;
    object-fit: contain;
    font-size: rem(10px);
  }
}

.basic-edit-image-holder {
  img {
    width: 100%;
    height: rem(101px);
    object-fit: contain;
  }
}

.alconInput {
  &__checkbox {
    display: none;

    & + label {
      display: flex;
      align-items: center;

      &:before {
        background-color: #ffffff;
        border: 1px solid #d4d4d4;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 5px;
        content: '';
      }
    }

    &:checked {
      & + label {
        &:before {
          content: '';
          background: url('./assets/images/checkbox-tick.svg');
          background-size: 100%;
          background-repeat: no-repeat;
          border: 0px;
        }
      }
    }

    &.disabled {
      color: transparent !important;
      background: #e5f5ff !important;

      & + label {
        display: flex;
        align-items: center;

        &:before {
          background-color: #e5f5ff;
          border: 1px solid #d4d4d4;
          border-radius: 3px;
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 5px;
          content: '';
        }
      }
    }

    &:disabled {
      & + label {
        &:before {
          content: '';
          background: url('./assets/images/disabledtick_new.svg');
          border: 0;
        }
      }

      &:checked {
        & + label {
          &:before {
            content: '';
            background: url('./assets/images/checkbox_checked_disabled.svg');
          }
        }
      }
    }
  }

  &__radio {
    display: none;

    & + label {
      display: flex;
      align-items: center;
      position: relative;

      &:before {
        content: '';
        background-color: #ffffff;
        border: 1px solid #d4d4d4;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    &:checked {
      & + label {
        &:before {
          border: 1px solid #00aeef;
        }

        &:after {
          background-color: #00aeef;
          border: 1px solid #00aeef;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          position: absolute;
          left: 10px;
          content: '';
        }
      }
    }

    &:disabled {
      & + label {
        &:before {
          content: '';
          border: 1px solid #6d6d6d;
        }
      }

      &:checked {
        & + label {
          &:before {
            border: 1px solid #6d6d6d;
          }

          &:after {
            background-color: #6d6d6d;
            border: 1px solid #6d6d6d;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            position: absolute;
            content: '';
          }
        }
      }
    }
  }
}

// stylelint-disable value-keyword-case
$font-family-sans-serif: OpenSans-Light;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

.loginpage-wrapper {
  background-image: url(assets/images/loginpage-background/webp/BackgroundCircle.webp);
  background-position: left -20px top -165px;
  background-repeat: no-repeat;
  background-size: 70rem 70rem;
  padding-left: 0;
  padding-right: 0;
}

footer .navbar {
  padding: 0rem 1rem !important;
}

.alcon-notification {
  .image-holder {
    img {
      margin-bottom: 10px;
      margin-top: 10px;
      max-height: 90px;
      max-width: 155px;
    }
  }
}

.UKMarketingLoginPageTemplate .new-order {
  display: none !important;
}

.new-order-nav {
  cx-media img {
    max-width: 100%;
    max-height: 100%;
  }
}

cx-storefront {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 6.25rem);
  &:focus {
    outline: none;
  }
  &.VCReturnFormPageTemplate,
  &.VCContactusPageTemplate {
    background-image: url(./assets/images/marketing-background-image.svg);
    background-repeat: no-repeat;
    background-position: rem(-950px) rem(-1200px);
    background-size: rem(2200px);
    margin-top: rem(-50px);
  }
}

.dropdown-menu icon cx-media img {
  max-width: 22px;
  max-height: 20px;
}

.mktBanner cx-media img {
  width: 100%;
}

ngb-pagination {
  li {
    & a {
      background-color: $white !important;
      border: none !important;
      box-shadow: none !important;
      color: $navy-blue !important;
      font-size: 1rem !important;

      &.page-link {
        padding: 0.5rem 0.5rem;
      }
    }

    &.disabled a {
      color: transparent !important;
      background: none !important;
    }

    &:first-child a,
    &:last-child a {
      background: url('/assets/Icons/pagination-arrow.svg') no-repeat right;
      background-size: rem(23px);
      height: 2.5rem;
      padding: 0;
      width: rem(30px);

      span {
        display: none !important;
      }
    }

    &:first-child a {
      transform: rotate(180deg);
    }
  }

  .active a {
    color: $deepSkyBlue !important;
  }
}

.errorDisplay {
  color: $bright-red;
  font-family: $openSans-regular;
  font-size: rem(12px);
  line-height: rem(15px);

  &--orange {
    color: $orange;
  }
}

.Alcon-form {
  &__error-message {
    color: #9b192e;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    padding-top: 5px;

    &--left {
      text-align: left;
    }
  }

  &__valid-input-field {
    border-color: $greenHaze !important;
    background-image: url(/assets/Icons/valid-input.svg);
    background-position: calc(100% - 1rem) center;
    background-repeat: no-repeat;
    background-size: 21px 22px;
    padding-right: 64px;
  }

  &__invalid-input-field {
    border-color: $bright-red !important;
    background-image: url(/assets/Icons/invalid-input.svg);
    background-position: calc(100% - 1rem) center;
    background-repeat: no-repeat;
    background-size: 21px 22px;
    padding-right: 64px;
  }
}

.orders {
  &__filter-row {
    display: flex;
    margin-top: rem(24px);
  }

  &__calender-icon {
    position: absolute;
    right: 20px;
    bottom: 15px;
    cursor: pointer;
    z-index: 1000;
  }

  &__date-filter {
    display: flex;
    position: relative;
  }

  &__date-filter-input {
    background-color: $white;
    border: 1px solid $lightGray;
    border-radius: 25px;
    height: 50px;
    width: 100%;
    font-size: rem(16px);
    line-height: 22px;
    text-align: left;
    padding-left: rem(10px);
    color: $dimBlack;
    font-family: $openSans-regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: rem(20px);
    width: 259px;
  }

  &__date-filter-label {
    color: $dimBlack;
    font-family: 'OpenSans-SemiBold';
    font-size: rem(18px);
    display: flex;
    margin-right: rem(25px);
    margin-top: rem(10px);
  }

  &__button-box {
    display: flex;
    margin-left: rem(38px);
  }
}

.product-tile__image-holder,
.variantSelector__image__product-tile__image-holder {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.productSelector__product-tile__image-holder {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.p-datepicker table td.p-datepicker-today > span {
  background: none !important;
}

.p-datepicker-title {
  color: $navy-blue !important;
  font-family: $openSans-semiBold;
  font-size: rem(16px);
  width: 190px;
  text-align: center;
}

.p-datepicker {
  height: 100%;
  width: 301px;
  padding: 0px !important;
  border: none !important;
}

.p-datepicker-header {
  padding-left: rem(16px) !important;
  padding-right: rem(16px) !important;
}

.p-datepicker-prev-icon,
.p-datepicker-next-icon {
  color: $navy-blue !important;
}

.p-datepicker-header {
  border-bottom: none !important;
}

.p-datepicker-calendar-container {
  padding-top: rem(5px);
  padding-left: rem(13px);
  padding-right: rem(13px);
}

.p-datepicker-calendar > thead > tr th {
  color: rgba(35, 35, 35, 0.3);
  font-family: $openSans-semiBold;
  font-size: rem(12px);
  line-height: 14px;
  text-align: center;
  padding-bottom: rem(16px);
}

.p-datepicker table td {
  font-size: 12px !important;
  font-family: $openSans-semiBold;
  padding: 0 !important;
}

.p-datepicker table td > span {
  height: 36px !important;
  width: 36px !important;
}

.p-datepicker table td > span.p-highlight {
  background: $lightSkyBlue !important;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: $lightSkyBlue !important;
}

.product-modal {
  padding: rem(24px) rem(25px) 0 rem(50px);
  background-color: $lightSteelBlue;
  border-radius: rem(15px);

  @media (min-width: $breakpoint-tablet) {
    padding: rem(24px) rem(25px) rem(87px) rem(50px);
  }

  &__close {
    padding-bottom: rem(22px);
  }

  &__header {
    color: $dimBlack;
    font-family: $openSans-bold;
    font-size: rem(18px);
    line-height: rem(25px);
    text-align: left;
  }
}

// custom class for alert popup in patient order page
.patientOrderList_alertPopup .modal-dialog {
  border-radius: 10px;
  max-width: rem(538px);
  height: rem(252px);
}

.creditCard_alertPopup .modal-dialog {
  background-color: $white;
  border-radius: rem(20px);
  min-height: rem(400px);
  max-width: rem(700px);
  & .modal-content {
    border-radius: rem(20px);
    border: none;
  }
}
.additional-account_accessPopup .modal-dialog {
  border-radius: rem(20px);
  max-width: rem(866px);
  height: rem(384px);
  transform: translate(0, -50px) !important;
  & .modal-content {
    border-radius: rem(20px);
    border: none;
  }
}
.marketing-Banner_Popup {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  & .modal-content {
    border-radius: rem(20px);
    border: none;
  }
}
.marketing-Banner_Popup > .modal-dialog {
  width: fit-content;
}

.returnFormProductSelector {
  .dropdown {
    &-input {
      font-size: rem(16px);
    }

    &-options {
      &__content {
        max-height: 200px;
      }

      &__item {
        min-height: 40px;
        font-size: rem(16px);
      }
    }
  }
}

.patient-profile-addr .address__header-label {
  padding: rem(22px) 0px 0px 0px;
}

.cancelOrderList_alertPopup .modal-dialog {
  border-radius: 10px;
  max-width: rem(700px);
  height: rem(252px);

  .alertPopup__secondaryBtn {
    border-radius: 20px;
    width: 188px;
    height: 40px;
  }

  .alertPopup__text {
    display: flex;
    flex-direction: column-reverse;
  }
}

.addOtherLens {
  &__button_background {
    border: 1px solid $navy-blue;
    border-radius: rem(20px);
    width: rem(190px);
    height: rem(40px);
    color: $navy-blue;
    font-family: $openSans-bold;
    font-size: rem(16px);
    text-align: center;

    &:disabled {
      color: $classicMatteGrey;
      border: 2px solid $classicMatteGrey;
      pointer-events: none;
    }
  }
}

.deleteSecondProduct {
  &__deleteItem {
    &--icon-remove {
      display: flex;
      cursor: pointer;
      font-size: 0.625rem;
      margin-left: 40px;
      margin-top: 10px;

      &--deleteLink {
        color: $deepSkyBlue;
        margin-top: 0.1875rem;
        margin-left: rem(10px);
        text-decoration: underline;
        font-family: $openSans-semiBold;

        &:hover {
          color: $deepSkyBlue;
        }
      }
    }
  }
}

.cart_summary_buttonwrapper {
  width: 100%;

  .cancel {
    &__button {
      width: 100% !important;
      height: 50px !important;
      border-radius: rem(25px) !important;
      margin-top: rem(47px);
      margin-bottom: rem(30px);
    }
  }

  .reorder-button {
    background-color: $navy-blue !important;
    color: $white !important;
    cursor: pointer !important;
  }

  .reorder-button-disable {
    border: none !important;
    background-color: $lightGray !important;
  }
}

.cart-dtp-address {
  .address__block {
    font-size: rem(12px);
    line-height: rem(15px);
    margin: 0px;
  }
}

.orderRow:hover .reorder-patient-button {
  background-color: $water-blue !important;
}

/*bootstrap popover style override */
.popover .arrow {
  display: none;
}

/*bootstrap popover style override */
.autoOrderingTimePicker {
  app-timepicker {
    fieldset {
      display: inline-block;
      margin-top: rem(-2px);
    }

    .timepick-tp {
      display: flex;
      align-items: center;
      .timepick-tp-input-container {
        width: 2rem;
        .timepick-tp-input {
          text-align: center;
          font-size: rem(12px);
          color: $navy-blue;
          padding: 0;
          font-weight: bold;
          background: inherit;
        }
      }

      .timepick-tp-meridian .btn {
        border: 1px solid $gray;
        padding: rem(5px);
        font-size: rem(12px);
        color: $navy-blue;
        font-weight: bold;
      }
      .timepick-tp-spacer {
        width: 1em;
        text-align: center;
      }

      .btn-outline-primary {
        &:hover {
          color: $white;
          background-color: $navy-blue;
        }
      }
    }
    .errorStyle {
      border: 1px solid red;
    }
  }
}

app-alcon-auto-ordering {
  .timeError {
    color: $red;
    font-size: 14px;
    margin: 10px 0px 0px 24px;
  }

  .errorStyles {
    border: 1px solid red;
  }
}
.patient-order--options,
.product-details__row {
  .alconInput__radio:checked + label:after {
    left: 5px;
  }
}
.orders {
  .popover-body {
    font-size: rem(12px);
    line-height: rem(15px);
    width: rem(162px);
  }
  .orders__column--search .bs-popover-bottom,
  .orders__columnDashboard--search .bs-popover-bottom {
    margin-left: 5rem;
  }
  .bs-popover-bottom {
    margin-left: 0rem;
  }
}
app-add-new-patient {
  app-dropdown-selector-v2 {
    .dropdown-header {
      height: 50px;
      display: flex;
      align-items: center;
      font-size: rem(16px);
    }
  }
}

li.dropdown.show .dropdown-menu .dropdown-item {
  icon cx-media img {
    max-width: 30px;
    max-height: 30px;
  }
}

app-patient-address-form {
  app-dropdown-selector-v2 {
    .dropdown-header {
      height: 50px;
      display: flex;
      align-items: center;
      font-size: rem(16px);
      font-weight: 400;
    }

    .dropdown-options__item {
      font-weight: 400;
    }
  }
}

@media (max-width: $breakpoint-tablet-max) {
  app-single-poduct-tile,
  app-cart-single-line-item {
    app-modal.stock-order-tablet {
      .modal-dialog {
        min-width: rem(728px);
        max-width: 100%;
        margin: rem(20px);
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  app-modal.stock-order {
    .modal-dialog {
      margin-bottom: rem(300px);
    }
  }
}

app-alcon-patient-profile app-address app-address-block {
  padding: 0;
}

app-alcon-patient-profile {
  app-address-block {
    .line-1,
    .line-2,
    .city,
    .region {
      @media (min-width: $breakpoint-tablet) {
        font-size: rem(16px);
      }
      font-size: rem(12px);
    }
  }
}

app-product-details {
  app-patient-info-selection {
    .address__no-address {
      margin-bottom: rem(10px);
    }
  }
}

app-invoices-list {
  app-dropdown-selector {
    .dropdown__select,
    .dropdown__options {
      font-weight: 400;
    }
  }
}

.MultiAccountSelectorTemplate {
  background-image: url(assets/images/marketing-background-image.svg);
  background-repeat: no-repeat;
  background-position: -800px -1083px;
  background-size: 1969px;
  margin-top: rem(-55px);
  padding-top: rem(70px);
}

//custom class for ship to address popup
.shipto_alertPopup .modal-dialog {
  background-color: $white;
  border-radius: rem(20px);
  box-shadow: 0px 22px 30px rgba(0, 0, 0, 0.1);
  & .modal-content {
    border-radius: rem(20px);
    border: none;
    padding: 0.79rem;
    max-height: rem(1575px);
    min-height: rem(563px);
  }

  & .modal-header {
    border-bottom: none;
    padding: 0.4rem 1rem 0rem;
    position: relative;
    z-index: 1;
  }
  & .modal-body {
    padding: 0rem 0rem 0rem rem(18px);
  }
}

.soldto_alertPopup .modal-dialog {
  background-color: $white;
  border-radius: rem(20px);
  box-shadow: 0px 22px 30px rgba(0, 0, 0, 0.1);

  & .modal-content {
    border-radius: rem(20px);
    border: none;
    padding: 0.79rem;
    min-height: rem(563px);
  }
  & .modal-header {
    border-bottom: none;
    padding: 0.4rem 1rem 0rem;
  }
}

.true-blue-backdrop {
  background-color: $alcon-true-blue;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.75 !important;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 900;
}

.site-not-found__nav {
  nav {
    @media (max-width: $breakpoint-mobile-max) {
      flex-wrap: nowrap;
    }
  }
}

//custom class for alert popup
.manageRolePermission_alertPopup .modal-dialog {
  border-radius: 10px;
  max-width: rem(700px);
  height: rem(297px);
  & .modal-content {
    border-radius: rem(10px);
  }
  & .modal-header {
    padding-top: 0rem;
  }
  & .modal-footer {
    margin-bottom: 0.5rem;
  }
  .alertPopup__text {
    padding-top: rem(30px);
  }
  .alertPopup__text__infoMsg {
    font-family: $openSans-bold;
  }
  .alertPopup__text__confirmationMsg {
    font-family: $openSans-regular;
  }
  .alertPopup__secondaryBtn {
    border-radius: 20px;
    width: 188px;
    height: 40px;
    &:focus {
      background-color: $navy-blue;
      box-shadow: none !important;
      outline: none;
    }
  }
}

.OrderConfirmationMessagePosition cx-paragraph div {
  padding-bottom: 1rem;
}

.migration_popup .modal-dialog {
    width: rem(400px);
    min-width: rem(300px);
    max-width: rem(1055px);
}