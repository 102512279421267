cx-asm-main-ui {
  position: relative;
  z-index: 2;

  .close,
  .logout {
    cursor: pointer;
    padding: 0;
    flex: none;
    width: 16px;
    height: 16px;
    border: transparent;
    background-color: transparent;
  }

  .close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='black' d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'/%3E%3C/svg%3E");
  }

  .logout {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%230064D9' d='M11,2.7c1.2,0.6,2.2,1.5,2.9,2.6c1.3,1.9,1.5,4.4,0.6,6.5c-0.3,0.8-0.8,1.6-1.5,2.2c-0.6,0.6-1.4,1.1-2.2,1.5 C9.9,15.8,9,16,8,16c-0.9,0-1.9-0.2-2.7-0.5c-0.8-0.4-1.6-0.9-2.2-1.5c-0.6-0.6-1.1-1.4-1.5-2.2C0.7,9.6,0.9,7.2,2.1,5.3 c0.7-1.1,1.7-2,2.9-2.6v1.1C4.1,4.3,3.3,5.1,2.8,6C2.3,6.9,2,7.9,2,9c0,1.6,0.6,3.2,1.8,4.3c0.5,0.5,1.2,1,1.9,1.3 c1.5,0.6,3.2,0.6,4.7,0c0.7-0.3,1.4-0.7,1.9-1.3C13.4,12.1,14,10.6,14,9c0-1.1-0.3-2.1-0.8-3c-0.5-0.9-1.3-1.7-2.2-2.2 C11,3.8,11,2.7,11,2.7z M8,9C7.7,9,7.5,8.9,7.3,8.7C7.1,8.5,7,8.3,7,8V1c0-0.3,0.1-0.5,0.3-0.7c0.4-0.4,1-0.4,1.4,0 C8.9,0.5,9,0.7,9,1v7c0,0.3-0.1,0.5-0.3,0.7C8.5,8.9,8.2,9,8,9z'/%3E%3C/svg%3E%0A");
  }

  button[type='submit'] {
    padding: 0 12px;
    white-space: nowrap;
    border-radius: 4px;
    height: 36px;
    font-weight: 400;
    border-style: solid;
    border-width: 1px;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &.hidden {
    display: none;
  }
  .asm-bar {
    margin-top: rem(-51px);
    color: #fff;
    background-color: #354a5f;
    height: 48px;
    display: flex;
    padding: 0 2rem;
    justify-content: space-between;
    z-index: 1;

    &-branding {
      display: flex;
      align-items: center;

      .logo {
        margin-inline-end: 8px;
      }

      .asm-title {
        font-size: 16px;
        font-weight: 700;
      }
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .cx-asm-customer-list {
        cursor: pointer;
        cx-icon {
          padding-top: 0;
          padding-inline-end: 7px;
          padding-bottom: 0;
          padding-inline-start: 0;
          color: #1e3050;
        }
        display: none;
        .cx-asm-customer-list-link {
          color: #0064d9;
          text-decoration: none;
        }
        .cx-asm-customer-list-link:hover {
          text-decoration: underline;
        }
      }
    }

    color: inherit;
    background-color: #ffffff;
    box-shadow: 2px 2px rgba(85, 107, 130, 0.1), inset 0px -1px 0px rgba(85, 107, 130, 0.2);
  }

  > *:nth-child(2) {
    padding: 1rem 2rem;
    display: flex;
    width: 100%;
  }

  cx-asm-toggle-ui {
    cursor: pointer;
    display: flex;
    align-items: center;

    height: 16px;
    margin: 0 15px;

    .toggleUi {
      display: inherit;
      align-items: inherit;

      .label {
        margin-inline-start: 5px;
        color: #d50101;
      }

      .collapseIcon,
      .expandIcon {
        width: 16px;
        height: 16px;
      }

      .collapseIcon {
        background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-circle-up' class='svg-inline--fa fa-chevron-circle-up fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%230064D9' d='M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm231-113.9L103.5 277.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L256 226.9l101.6 101.6c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L273 142.1c-9.4-9.4-24.6-9.4-34 0z'%3E%3C/path%3E%3C/svg%3E")
          center center no-repeat;
      }

      .expandIcon {
        background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-circle-down' class='svg-inline--fa fa-chevron-circle-down fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%230064D9' d='M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM273 369.9l135.5-135.5c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L256 285.1 154.4 183.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L239 369.9c9.4 9.4 24.6 9.4 34 0z'%3E%3C/path%3E%3C/svg%3E")
          center center no-repeat;
      }
    }
  }
  cx-asm-session-timer {
    display: flex;
    align-items: center;
    height: 16px;
    margin: 0 15px;

    .label {
      margin: 0 6px;
    }
    .time {
      font-weight: 600;
    }
    .reset {
      margin: 0 15px;
      cursor: pointer;

      width: 16px;
      height: 16px;

      background: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%230064D9' d='M14.9,7.5l-1,0.2c0.2,0.9,0.1,1.7-0.1,2.5c-0.3,1-0.8,2-1.5,2.7c-1.1,1.1-2.7,1.8-4.2,1.8 c-0.8,0-1.5-0.1-2.3-0.4c-1.5-0.6-2.7-1.8-3.3-3.3C2.1,10.2,2,9.5,2,8.7c0-1.6,0.7-3.1,1.8-4.3c0.7-0.8,1.7-1.3,2.7-1.5 c1-0.3,2-0.2,3,0l0,0v-1c-1-0.2-2.1-0.2-3.1,0C4.2,2.4,2.4,4,1.5,6.1C1.2,6.9,1,7.8,1,8.7c0,0.9,0.2,1.8,0.5,2.6 c0.4,0.9,0.9,1.7,1.5,2.3c0.7,0.7,1.4,1.2,2.3,1.5c0.8,0.3,1.7,0.5,2.6,0.5c0.9,0,1.8-0.2,2.6-0.5c2.1-0.9,3.7-2.7,4.2-5 C15,9.3,15,8.4,14.9,7.5z'/%3E%3Cpolygon fill='%23d1e3ff' points='11.5,2.8 9.2,4.5 9.7,0.5 '/%3E%3C/svg%3E%0A")
        no-repeat center center;

      border: transparent;
      background-color: transparent;
    }
  }
  input {
    outline: 0;
    border: 1px solid #89919a;
    color: #32363a;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 12px;
    height: 36px;

    &:focus {
      box-shadow: 0 0 0 1px #fafafa;
    }

    &:hover {
      border-color: #085caf;
    }
    &::placeholder {
      color: #74777a;
      font-style: italic;
    }
  }
  cx-csagent-login-form {
    form {
      display: flex;
      width: 100%;

      label {
        margin: 0 0 15px;
        min-width: auto;
        margin-inline-end: 15px;
        margin-top: 0;
        margin-bottom: 0;
        margin-inline-start: 0;
        min-width: 15rem;

        input {
          width: 100%;
        }
      }
    }

    .label-content {
      max-width: 110px;
      margin-inline-end: 6px;
      white-space: nowrap;
      @media (min-width: 575px) {
        padding-top: 6px;
      }
    }

    button[type='submit'] {
      color: #fff;
      border-color: #0a6ed1;
      background-color: #0a6ed1;
      &:hover {
        background-color: #085caf;
      }
    }
  }
  cx-customer-selection {
    position: relative;

    form {
      display: flex;
      width: 100%;

      label {
        margin: 0 0 15px;
        min-width: auto;
        margin-inline-end: 15px;
        min-width: 20rem;
        margin-bottom: 0;
        min-width: 25rem;

        .input-contaier {
          display: flex;
          .icon-wrapper {
            display: flex;
            outline: 0;
            border: 1px solid #89919a;
            color: #ffffff;
            background-color: #1672b7;
            padding: 0 12px;
            height: 36px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right-width: 0px;
            cursor: pointer;
          }
          input {
            border: none;
            border-radius: 4px;
            background-color: #eff1f2;
            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
          }
        }

        .searchTermLabel {
          display: flex;
          align-items: center;
          color: #556b82;
          min-width: auto;
          margin: 0 15px 0 0;
        }
        input {
          width: 100%;
        }
      }

      button[type='submit'] {
        background-color: #ebf5cb;
        opacity: 0.4;
        border: 1px solid #ebf5cb;
        border-radius: 8px;
        color: #256f3a;
        font-weight: 700;
        transition: opacity 0.3s;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  cx-customer-emulation {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    cx-asm-bind-cart {
      display: none;
    }

    button {
      color: #aa0808;
      font-weight: 700;
      background-color: #ffd6ea;
      border: 1px solid #ffd6ea;
      border-radius: 8px;
      padding: 6px 10px;
    }

    label {
      margin-inline-end: 10px;
      margin-inline-start: 0;
      margin-top: 0;
      margin-bottom: 0;
      color: #556b82;
    }
    .cx-asm-customerInfo {
      display: flex;
      flex-direction: row !important;
      margin-inline-end: 15px;
      border: 1px solid #89919a;
      flex: 1;
      outline: 0;
      color: #32363a;
      background-color: #fff;
      border-radius: 4px;
      padding: 7px 12px;
      height: 36px;

      .cx-asm-name {
        color: #556b82;
      }
      .cx-asm-uid {
        color: #556b82;
      }
    }
  }
  .asm-results {
    border: solid 1px #89919a;
    position: absolute;
    line-height: 1.5rem;
    left: 2rem;
    top: 4rem;
    z-index: 11;
    box-shadow: 0 5px 20px 0 #d9d9d9, 0 2px 8px 0 #ededed;
    background-color: #fff;
    border-radius: 4px;
    width: 100%;
    max-width: 50vw;
    max-height: 70vh;
    overflow-y: auto;

    button {
      margin: 0;
      appearance: none;
      outline: 0;
      border: 0;
      text-decoration: none;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      background-color: transparent;
      color: #51555a;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 10px;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;

      &.active {
        color: #32363a;
        background-color: #d3d6db;
      }

      &:hover {
        color: #32363a;
        background-color: #d3d6db;
      }

      span {
        margin-inline-end: 15px;
        margin-top: 0;
        margin-bottom: 0;
        margin-inline-start: 0;
        word-break: break-all;
        white-space: normal;
        text-align: start;
      }
    }
  }
  .cx-asm-bind-cart {
    display: none;
  }

  input.ng-invalid.ng-dirty,
  input.ng-invalid.ng-touched {
    border-color: $red;
  }
  .form-control:focus,
  input:focus {
    outline-style: solid;
    outline-color: $lightSkyBlue;
    outline-width: rem(2px);
    outline-offset: rem(4px);
    transition: none 0s ease 0s;
  }
  cx-form-errors {
    display: none;
    &.control-invalid {
      &.control-dirty,
      &.control-touched {
        display: initial;
      }
    }
    p {
      margin: rem(6px) 0;
      padding-inline-start: rem(25px);
      position: relative;
      word-break: break-word;
      &::before,
      &::after {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: rem(20px);
        height: rem(20px);
      }

      &::before {
        content: '';
        background-color: $red;
        border-radius: 50%;
      }

      &::after {
        content: '!';
        color: $white;
        font-weight: bold;
        text-align: center;
        line-height: rem(20px);
      }

      &:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }
}

// cx-csagent-login-form
cx-password-visibility-toggle {
  display: none;
}

.cx-message-content .cx-message-header {
  padding: 0 2rem 1rem;
}
